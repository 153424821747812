import Translate from 'i18n-react';
import React, {useMemo, useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';

import AddToCollections from '../../components/Product/UserCollections/AddToCollections';
import UserCollectionsButton from '../../components/Product/UserCollections/UserCollectionsButton';
import LinkWrapper from '../../components/Reusable/LinkWrapper/LinkWrapper';
import {PopoverContainer} from '../../components/Reusable/Popover/PopoverContainer';
import {EN} from '../../constants/SupportedLanguages';
import {translate} from '../../services';
import {collectionsExist, UserCollections} from '../../shapes/collections';
import {TagsProps} from '../../shapes/tags';
import {State} from '../../store/initialState';
import {openModalDispatcher, closeModalDispatcher} from '../../utils/dispatchToPropsHelpers';
import {createCollection, addToOrRemoveFromCollection} from '../../utils/fetch';
import {isEmpty, isNotUndefined} from '../../utils/validation';
import ModalContainer from '../Modal/ModalContainer';

import fetchUserCollections from './api/fetchUserCollections';


export interface UserCollectionsContainerProps {
  designTitle: string;
  designerUserName: string;
  designId: number;
  imageUrl: string;
  tags: TagsProps;
  reloadCollection?: () => void;
  productType?: string;
  redirectToLogin?: boolean;
}

const UserCollectionsContainer = ({
  designId,
  designTitle,
  designerUserName,
  productType = '', tags, imageUrl, reloadCollection, redirectToLogin
}: UserCollectionsContainerProps): JSX.Element | null => {
  const {
    displayModal,
    locale,
    modalName,
    userCollections,
    userId,
    viewport
  } = useSelector((state: State) => ({
    displayModal: state.pageSetup.modal.displayModal,
    locale: state.user.preferences.locale || EN,
    modalName: state.pageSetup.modal.modalName,
    userCollections: state.userCollections,
    userId: state.user.id as number,
    viewport: state.pageSetup.viewport,
  }), shallowEqual);

  const [isSearching, setIsSearching] = useState(false);
  const [showCreateCollections, setShowCreateCollections] = useState(false);
  const [displayLoginPopover, setDisplayLoginPopover] = useState(false);
  const dispatch = useDispatch();
  const ref = useRef(null);
  const dispatchCloseModal = closeModalDispatcher(dispatch);
  const userCollectionsResults = useMemo(() => (userCollections && collectionsExist(userCollections) ? userCollections.collections : []), [userCollections]);
  const isCollectionFetching = userCollections.isFetching;

  const createCollectionClick = (userId: number, designId: number, collectionName: string, collectionDescription: string, showCollection: boolean) => {
    createCollection(dispatch, userId, designId, collectionName, collectionDescription, showCollection);
    setShowCreateCollections(false);
  };

  const addOrRemoveDesign = (collectionId: number, designId: number, removeFromCollection: boolean) => {
    addToOrRemoveFromCollection(dispatch, collectionId, designId, removeFromCollection);
    setIsSearching(false);
    reloadCollection && reloadCollection();
  };

  const getRecentUserCollections = () => {
    const listDate = userCollectionsResults.sort((a, b) => (a.last_design_added_at > b.last_design_added_at ? -1 : 1));
    const recentUserCollections = listDate.slice(0, 2);

    return recentUserCollections.sort((a, b) => a.name.localeCompare(b.name));
  };

  const designsInCollections = useMemo(() => userCollectionsResults.filter((collection) => collection.design_ids.includes(designId)), [designId, userCollectionsResults]);
  const isDesignCollected = useMemo(() => userCollectionsResults.some((collection) => collection.design_ids.includes(designId)), [designId, userCollectionsResults]);
  const designInCollectionsCount = designsInCollections.length;
  const uniqueId = `addToCollections_${designId}_${productType}`;

  const toggleModal = () => {
    checkForUserCollections();
    openModalDispatcher(dispatch)(uniqueId);
  };

  const userIsSearching = (userCollectionQuery: string) => setIsSearching(userCollectionQuery !== '');

  const onCollectionsButtonClick = () => {
    if (redirectToLogin) {
      setDisplayLoginPopover((prevState) => !prevState);
    } else {
      toggleModal();
    }
  };

  const checkForUserCollections = () => {
    const showCreateForm = isEmpty(userCollections);

    setShowCreateCollections(showCreateForm);
  };

  const handleClearSearch = () => setIsSearching(false);
  const handleShowCreateCollectionForm = () => setShowCreateCollections(true);
  const handleHideCreateCollectionForm = () => setShowCreateCollections(false);

  const PopoverContent = (
    <div>
      <LinkWrapper className='auth-link' hrefValue={Translate.translate('xurls.login')?.toString() || ''} contents={translate('common.login')} />
      {translate('common.or')}
      <LinkWrapper className='auth-link' hrefValue={Translate.translate('xurls.header.join')?.toString() || ''} contents={translate('common.createAccount')} />
      {translate('designs.makeCollection')}
    </div>
  );

  return (
    <div ref={ref}>
      <PopoverContainer
        content={PopoverContent}
        open={displayLoginPopover}
        onClose={() => setDisplayLoginPopover((prevState) => !prevState)}
        anchorElRef={ref.current}
      />
      {displayModal && modalName === uniqueId && (
        <ModalContainer
          slideInModal={displayModal}
          modalWithButton={true}
          modalExtraWide={false}
          displayFabricsModal={false}
          contents={
            <AddToCollections
              closeModal={dispatchCloseModal}
              designId={designId}
              designTitle={designTitle}
              designerUserName={designerUserName}
              imageUrl={imageUrl}
              tags={tags}
              locale={locale}
              recentUserCollectionsResults={getRecentUserCollections()}
              designsInCollections={designsInCollections}
              userCollectionsResults={userCollectionsResults}
              designInCollectionCount={designInCollectionsCount}
              userId={userId}
              addOrRemoveDesign={addOrRemoveDesign}
              userIsSearching={userIsSearching}
              isSearching={isSearching}
              showCreateCollections={showCreateCollections}
              createCollectionClick={createCollectionClick}
              showCreateCollectionForm={handleShowCreateCollectionForm}
              hideCreateCollectionForm={handleHideCreateCollectionForm}
              clearSearch={handleClearSearch}
              viewport={viewport}
              isCollectionFetching={isCollectionFetching}
            />
          }
        />
      )}
      <UserCollectionsButton
        designId={designId}
        openModal={onCollectionsButtonClick}
        clearSearch={handleClearSearch}
        designInCollection={isDesignCollected}
      />
    </div>
  );
};

const UserCollectionsContainerWrapper = (props: UserCollectionsContainerProps): JSX.Element | null => {
  const {user, userCollections} = useSelector((state: State) => ({
    user: state.user,
    userCollections: state.userCollections,
  }), shallowEqual);

  const dispatch = useDispatch();
  const {collections, isFetching} = (userCollections as UserCollections);
  const userId = user.id;

  useEffect(() => {
    if (!isFetching && !collections && isNotUndefined(userId)) {
      dispatch(fetchUserCollections());
    }
  }, [collections, dispatch, isFetching, userId]);

  return <UserCollectionsContainer {...props} />;
};

UserCollectionsContainerWrapper.fetchDataOnServer = new Set([fetchUserCollections]);

export default UserCollectionsContainerWrapper;
