import PropTypes from 'prop-types';

import {HOME_GOOD, WALLPAPER, FABRIC, FABRIC_BLANK, SOLID} from '../constants/Codes';
import {DATA_COLORWAYS_CATEGORIES} from '../constants/DataLayer';
import {MeasurementSystem} from '../constants/Measurements';
import {FabricShopCategory} from '../store/initialState';

// TODO SP-6816: change to enum when all references in typescript files

const productTypes = [HOME_GOOD, FABRIC, WALLPAPER, FABRIC_BLANK, SOLID] as const;

export const productTypeAbbreviatedShape = PropTypes.oneOf(productTypes);

export type ProductTypeAbbreviated =
  | typeof HOME_GOOD
  | typeof FABRIC
  | typeof WALLPAPER
  | typeof FABRIC_BLANK
  | typeof SOLID;

export type ColorwaysProductTypeAbbreviated = keyof typeof DATA_COLORWAYS_CATEGORIES

export const isProductType = (input: string | ProductTypeAbbreviated): input is ProductTypeAbbreviated => productTypes.includes(input as ProductTypeAbbreviated);

export interface Fabrics {
  fabrics: {
    [key: string]: {
      url?: string;
      active: boolean;
      oos: boolean;
      standby: boolean;
      pricing: {
        [measurementUnit: string]: {price_undiscounted: number};
      };
    };
  };
  categories?: Record<string, Record<string, FabricShopCategory>>;
  categories_groups?: Record<string, unknown>;
  measurement_system?: MeasurementSystem;
}

export interface WallpaperDimensions {
  id: number;
  name: string;
  width: number;
  height: number;
  dpi: number;
  repeatType: number;
  verticalRepeatSize: number;
  normalizedVerticalRepeatSize: number;
  normalizedVerticalRepeatSizeCm: number;
  normalizedHorizontalRepeatSize: number;
}
