import React, {useState, useRef} from 'react';

import {translate} from '../../../services';
import {Collections} from '../../../shapes/collections';
import Icon from '../../Reusable/Icon/Icon';

import UserCollectionsList from './UserCollectionsList';


export interface SearchCollectionsProps {
  userCollectionsResults: Collections[];
  designId: number;
  addOrRemoveDesign: (collectionId: number, designId: number, removeFromCollection: boolean) => void;
  userIsSearching: (query: string) => void;
  isSearching: boolean;
  closeModal: () => void;
  buttonTitleCreateNew: string;
  buttonTextCreateNew: string;
  buttonDone: string;
  showCreateCollectionForm: () => void;
}

const SearchCollections = ({
  isSearching, userIsSearching, designId, addOrRemoveDesign, closeModal, buttonTitleCreateNew,
  buttonTextCreateNew, buttonDone, showCreateCollectionForm, userCollectionsResults
}: SearchCollectionsProps): JSX.Element => {
  const searchQueryTextInput = useRef<HTMLInputElement | null>(null);
  const [userCollectionQuery, setUserCollectionQuery] = useState('');
  const [searchCollectionsResults, setSearchCollectionsResults] = useState<Collections[]>([]);

  const updateCollectionSearchQuery = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const userCollectionQuery = e.currentTarget.value;

    userIsSearching(userCollectionQuery);
    findInUserCollections(userCollectionQuery);
    setUserCollectionQuery(userCollectionQuery);
  };

  const findInUserCollections = (userCollectionQuery: string) => {
    const searchCollectionsResults: Collections[] = [];

    userCollectionsResults.forEach((collection) => {
      if (collection.name.toLowerCase().includes(userCollectionQuery.toLowerCase())) {
        searchCollectionsResults.push(collection);
      }
    });

    setSearchCollectionsResults(searchCollectionsResults);
  };

  return (
    <React.Fragment>
      <div className='b-form x-search-collections-wrapper'>
        <input id='x-search-collections-wrapper-input'
          type='text'
          autoComplete='off'
          placeholder={translate('collections.userCollections.queryPlaceholder')}
          defaultValue={userCollectionQuery}
          onKeyUp={updateCollectionSearchQuery}
          ref={searchQueryTextInput}
          className='text x-search-input'
        />
        <Icon iconName='search' iconTitle={translate('collections.userCollections.searchIconTitle')}/>
      </div>
      {(searchCollectionsResults && isSearching) &&
        <UserCollectionsList
          listTitle={translate('collections.userCollections.listTitleSearchResults')}
          userCollectionsResults={searchCollectionsResults}
          isAlphaOrder={true}
          designId={designId}
          addOrRemoveDesign={addOrRemoveDesign}
          buttonTitleCreateNew={buttonTitleCreateNew}
          buttonTextCreateNew={buttonTextCreateNew}
          buttonDone={buttonDone}
          showCreateCollectionForm={showCreateCollectionForm}
          closeModal={closeModal}
        />
      }
    </React.Fragment>
  );
};

export default SearchCollections;
