import {Popover} from '@mui/material';
import React from 'react';
import './_popover.scss';


interface LoginForActionProps {
  content: string | JSX.Element;
  open: boolean;
  onClose: () => void;
  anchorElRef?: HTMLElement | null;
}

export const PopoverContainer = ({content, open, onClose, anchorElRef}: LoginForActionProps): JSX.Element => (
  <div className='popover-container'>
    <Popover
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: -20,
        horizontal: 'center',
      }}
      anchorEl={anchorElRef}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      {content}
      <div className='arrow-down'></div>
    </Popover>
  </div>);
