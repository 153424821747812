import React, {useState, useEffect} from 'react';

import {translate} from '../../../services';
import {isNotUndefined} from '../../../utils/validation';
import Icon from '../../Reusable/Icon/Icon';
import LinkWrapper from '../../Reusable/LinkWrapper/LinkWrapper';


export interface UserCollectionsItemProps {
  collectionId: number;
  collectionTitle: string;
  collectionUrl: string;
  isPublic: boolean;
  collectionDesignIds: number[];
  designId: number;
  isCollectionFetching?: boolean;
  addOrRemoveDesign: (collectionId: number, designId: number, removeFromCollection: boolean) => void;
}

const UserCollectionsItem = ({
  collectionId,
  collectionDesignIds,
  collectionTitle,
  collectionUrl,
  isPublic,
  designId,
  addOrRemoveDesign,
  isCollectionFetching,
}: UserCollectionsItemProps): JSX.Element => {
  const [inCollection, setInCollection] = useState(false);

  useEffect(() => {
    const isDesignInCollection = (designId: number) => {
      if (isNotUndefined(collectionDesignIds)) {
        for (const val of collectionDesignIds) {
          if (val === designId) {
            return true;
          }
        }
      }

      return false;
    };

    setInCollection(isDesignInCollection(designId));
  }, [collectionDesignIds, designId]);

  const collectionButtonType = inCollection ? 'check' : 'add';
  const removeFromCollection = inCollection;
  const yamlCopy = collectionButtonType === 'add' ? translate('collections.userCollections.addTo') : translate('collections.userCollections.removeFrom');

  function handleAddOrRemoveFromCollection() {
    addOrRemoveDesign(collectionId, designId, removeFromCollection);
  }

  return (
    <li key={collectionId} className='user-collections-list-item'>
      <span className='collection-name'>{collectionTitle}</span>
      {!isPublic && <Icon
        iconName='lock'
        iconTitle={translate('collections.userCollections.privateIconTitle')}
      />}
      <LinkWrapper
        hrefValue={collectionUrl}
        className='link-to-collection'
        target='_blank'
        contents={<Icon
          iconName='view-eye'
          iconTitle={translate('collections.userCollections.linkIconTitle')}/>}
      />
      <button
        type='button'
        className={`collections-list-item-button ${collectionButtonType}-button`}
        onClick={handleAddOrRemoveFromCollection}
        aria-label={translate('collections.userCollections.titleButton', {
          yamlCopy
        })}
        title={translate('collections.userCollections.titleButton', {
          yamlCopy
        })}
        disabled={!!isCollectionFetching}
      >
        <Icon
          iconName={collectionButtonType}
          iconTitle={translate('collections.userCollections.titleIcon', {
            yamlCopy
          })}
        />
      </button>
    </li>
  );
};

export default UserCollectionsItem;
