import {AnyAction} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {serverError} from '../../../actions/server';
import {collectionsFetching, collectionsReceived} from '../../../actions/userCollections';
import request from '../../../application/api/request';
import selectAlpenroseHost from '../../../entities/pageSetup/apiHosts/selectors/selectAlpenroseHost';
import {selectUserId} from '../../../entities/user/selectors';
import {State} from '../../../store/initialState';
import {RequestError} from '../../../utils/errors';


interface User {
  id: number;
  screen_name: string;
}

export interface UserCollectionResponse {
  data: {
    collections: [{
      id: number;
      design_ids: Array<number>;
      designer_only: boolean;
      last_design_added_at: string;
      name: string;
      show_collection: boolean;
      slug: string;
      user: User;
    }];
    metadata: {
      count: number;
      page: number;
      items: number;
      pages: number;
    };
    user: User;
  };
}

export default function fetchUserCollections(): ThunkAction<Promise<void | UserCollectionResponse> | void, State, unknown, AnyAction> {
  return (dispatch, getState) => {
    const store = getState();
    const userId = selectUserId(store);
    const {isFetching} = store.userCollections;

    if (!isFetching && userId) {
      dispatch(collectionsFetching());

      return request<UserCollectionResponse>(store,{
        url: `${selectAlpenroseHost(store)}/collections/user/${userId}`
      })
        .then(({data}) => {
          dispatch(collectionsReceived(data));

          return data;
        })
        .catch((e: RequestError) => {
          if (process.env.REACT_APP_IS_SERVER) {
            dispatch(serverError(e));
          } else {
            console.log('Error caught in the `fetchUserCollections` function', e); // eslint-disable-line
          }
        });
    }
  };
}
