import classNames from 'classnames';
import React from 'react';

import {translate} from '../../../services';
import Icon from '../../Reusable/Icon/Icon';


export interface UserCollectionsButtonProps {
  designId: number;
  openModal: (designId: number) => void;
  clearSearch: () => void;
  designInCollection?: boolean;
}

const UserCollectionsButton = ({designId, openModal, clearSearch, designInCollection}: UserCollectionsButtonProps): JSX.Element => {
  const buttonClasses = classNames('btn', 'collection-btn', {
    'collected': designInCollection
  });

  function openCollectionsModal() {
    openModal(designId);
    clearSearch();
  }

  return (
    <button type='button' className={buttonClasses}
      aria-label={translate('collections.userCollections.addToButton')}
      title={translate('collections.userCollections.addToButton')}
      onClick={openCollectionsModal}>
      <Icon iconName='collection-add'/>
    </button>
  );
};

export default UserCollectionsButton;
