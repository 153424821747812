export interface Collections {
  design_ids: number[];
  designer_only: boolean;
  id: number;
  last_design_added_at: Date;
  name: string;
  show_collection: boolean;
  slug: string;
  user: {
    id: number;
    screen_name: string;
  };
}

export interface UserCollections {
  isFetching: boolean;
  collections: Collections[] | [];
  metadata: {
    count: number;
    items: number;
    page: number;
    pages: number;
  };
  user: {
    id: number;
    screen_name: string;
  } | {
    isFetching: boolean;
  };
}

export function collectionsExist(input: UserCollections | {isFetching: boolean}): input is UserCollections {
  return (input as UserCollections).collections !== undefined && (input as UserCollections).collections.length > 0;
}

export interface DesignThumbnail {
  url: string;
  width: number;
  height: number;
}

export type DesignThumbnails = Array<DesignThumbnail>

export interface Collection {
  // design_ids: number[];
  collection_name: string;
  description: string;
  design_docs: number[];
  design_thumbnails: DesignThumbnails;
  designer_only: boolean;
  id: number;
  num_public_designs: number; // numPublicDesigns in API
  slug: string;
  show_collection: boolean;
  user: {
    user_id: number;
    id: number;
    screen_name: string;
  };
  mugshot: {
    mugshot_id: number;
    mugshot_url: string;
  };
}
