import React, {ImgHTMLAttributes} from 'react';
import {useInView} from 'react-intersection-observer';

import './_lazy-image.scss';
import {isNotUndefined} from '../../../utils/validation';


interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  aspectRatio?: boolean;
}

const LazyImage = ({height, width, aspectRatio = false, alt = '', style: styleOverride, ...rest}: ImageProps): JSX.Element => {
  const [wrapperRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px' // offset for loading images
  });

  const image = <img height={height} width={width} alt={alt} {...rest} />;
  const hasDimensions = isNotUndefined(width) && isNotUndefined(height) && aspectRatio;

  const style = hasDimensions ? {
    paddingTop: `calc(${height} / ${width} * 100%)` // since aspect-ratio has limited support
  } : {
    display: 'inline-block'
  };

  // TODO: fix, ideally we don't want to do anything different for testing
  if (process.env.NODE_ENV === 'test') {
    return image;
  }

  return (
    <div ref={wrapperRef} className='b-lazy-image' style={styleOverride || style}>
      {hasDimensions ? (
        <div className='lazy-content'>
          {inView && image}
        </div>
      ): inView && image}
    </div>
  );
};

export default LazyImage;
