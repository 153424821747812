import classNames from 'classnames';
import React, {useState} from 'react';

import ModalButtonGroup from '../../Reusable/FormElements/ModalButtonGroup/ModalButtonGroup';


export interface CreateCollectionProps {
  inputNamePlaceholder: string;
  inputDescriptionPlaceholder: string;
  nameLabel: string;
  descriptionLabel: string;
  createCollectionWrapperClass: string;
  createCollectionClick: (userId: number, designId: number, collectionName: string, collectionDescription: string, showCollection: boolean) => void;
  checkboxLabelText: string;
  buttonTitleCreate: string;
  buttonTextCreate: string;
  buttonTitleCancel: string;
  buttonTextCancel: string;
  userId: number;
  designId: number;
  clearSearch: () => void;
  hideCreateCollectionForm: () => void;
}

const CreateCollection = ({
  createCollectionWrapperClass, inputNamePlaceholder, inputDescriptionPlaceholder, nameLabel, descriptionLabel,
  createCollectionClick, buttonTitleCreate, buttonTextCreate, buttonTitleCancel, buttonTextCancel,
  designId, userId, checkboxLabelText, clearSearch, hideCreateCollectionForm
}: CreateCollectionProps): JSX.Element => {
  const [collectionName, setCollectionName] = useState('');
  const [collectionDescription, setCollectionDescription] = useState('');
  const [showCollection, setShowCollection] = useState(false);
  const createCollectionWrapperClasses = classNames('b-form', createCollectionWrapperClass);

  function handleCollectionName(event: React.ChangeEvent<HTMLInputElement>) {
    setCollectionName(event.target.value);
  }

  function handleCollectionDescription(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setCollectionDescription(event.target.value);
  }

  function handleShowCollection(event: React.ChangeEvent<HTMLInputElement>) {
    setShowCollection(event.target.checked);
  }

  function handleCreateNewCollection() {
    createCollectionClick(
      userId, designId, collectionName, collectionDescription, showCollection
    );
    clearSearch();
  }

  return ( // TODO: EN-2368 Update Button Group to handle the case for buttons within collection modal
    <div className={createCollectionWrapperClasses}>
      <div className='x-create-collection-text-input-wrapper'>
        <label htmlFor='create-collection-name-input' className='x-create-creation-label'>{nameLabel}</label>
        <input id='create-collection-name-input'
          type='text'
          autoComplete='off'
          autoFocus={true}
          placeholder={inputNamePlaceholder}
          onChange={handleCollectionName}
          className='text x-create-collection-input'
        />
      </div>
      <div className='x-create-collection-text-input-wrapper'>
        <label htmlFor='create-collection-description-textarea' className='x-create-creation-label'>{descriptionLabel}</label>
        <textarea
          id='create-collection-description-textarea'
          placeholder={inputDescriptionPlaceholder}
          onChange={handleCollectionDescription}
          className='textarea x-create-collection-textarea'>
        </textarea>
      </div>
      <div className='checkbox x-create-collection-checkbox'>
        <input
          name='show-collection'
          id='create-collection-show-collection'
          type='checkbox'
          className='checkbox'
          onChange={handleShowCollection}/>
        <label htmlFor='create-collection-show-collection' className='label'>{checkboxLabelText}</label>
      </div>
      <ModalButtonGroup
        buttonPrimaryTitle={buttonTitleCreate}
        buttonPrimaryText={buttonTextCreate}
        buttonSecondaryTitle={buttonTitleCancel}
        buttonSecondaryText={buttonTextCancel}
        primaryOnClick={handleCreateNewCollection}
        secondaryOnClick={hideCreateCollectionForm}
      />
    </div>
  );
};

export default CreateCollection;
