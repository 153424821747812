import React from 'react';

import './_add-to-collections.scss';
import {thumbnailDimensions} from '../../../constants/Collections';
import {isTabletDown, TViewPort} from '../../../constants/Viewports';
import {translate} from '../../../services';
import {Collections} from '../../../shapes/collections';
import {TagsProps} from '../../../shapes/tags';
import {designDisplayTitle} from '../../../utils/tags';
import {isNotUndefined} from '../../../utils/validation';
import {ErrorBoundary} from '../../Reusable/ErrorBoundary/ErrorBoundary';
import LazyImage from '../../Reusable/LazyImage/LazyImage';

import CreateCollection from './CreateCollection';
import SearchCollections from './SearchCollections';
import UserCollectionsList from './UserCollectionsList';


export interface AddToCollectionsProps {
  userId: number;
  designId: number;
  designTitle: string;
  designerUserName: string;
  imageUrl: string;
  locale: string;
  tags: TagsProps;
  designInCollectionCount: number;
  recentUserCollectionsResults: Collections[];
  userCollectionsResults: Collections[];
  designsInCollections: Collections[];
  addOrRemoveDesign: (collectionId: number, designId: number, removeFromCollection: boolean) => void;
  userIsSearching: (userCollectionQuery: string) => void;
  isSearching: boolean;
  showCreateCollections: boolean;
  createCollectionClick: (userId: number, designId: number, collectionName: string, collectionDescription: string, showCollection: boolean) => void;
  closeModal: () => void;
  showCreateCollectionForm: () => void;
  hideCreateCollectionForm: () => void;
  clearSearch: () => void;
  isCollectionFetching: boolean;
  // TODO: SP-8365 Handle viewport and make required
  viewport: TViewPort;
}


const AddToCollections = ({
  userId, designId, imageUrl, designTitle, designerUserName, locale, tags, designInCollectionCount, createCollectionClick,
  recentUserCollectionsResults, userCollectionsResults, addOrRemoveDesign, userIsSearching, isSearching, showCreateCollections,
  closeModal, showCreateCollectionForm, hideCreateCollectionForm, clearSearch, viewport, designsInCollections, isCollectionFetching
}: AddToCollectionsProps): JSX.Element => {
  const tagsByLocale = isNotUndefined(tags) ? tags : [];
  const caption = designDisplayTitle(tagsByLocale, designTitle, locale);
  const viewportIsTabletDown = isTabletDown(viewport as TViewPort);

  const filteredCollections = () => {
    const collectionsToRemove = recentUserCollectionsResults.concat(designsInCollections);
    const filteredUserCollectionsResults = [...userCollectionsResults];

    collectionsToRemove.forEach((entry) => {
      filteredUserCollectionsResults.forEach((collection, index) => {
        (entry.id === collection.id) && filteredUserCollectionsResults.splice(index, 1);
      });
    });

    return filteredUserCollectionsResults;
  };

  return (
    <ErrorBoundary>
      <article className='b-add-to-collections'>
        <section className='design-wrapper'>
          <figure className='design-figure'>
            <LazyImage
              className='design-image'
              src={imageUrl}
              alt={`${designTitle} ${translate('fabric.content.by')} ${designerUserName}`}
              height={thumbnailDimensions}
              width={thumbnailDimensions}
            />
            {!viewportIsTabletDown && <figcaption className='design-name'>
              {caption}
            </figcaption>}
          </figure>
          {(!viewportIsTabletDown && (designInCollectionCount >= 0)) &&
            <p className='collection-count'>
              {translate('collections.userCollections.countText', {
                designInCollectionCount
              })}
            </p>}
        </section>
        {showCreateCollections &&
          <section className='user-collections-wrapper'>
            <h3 className='h2'>{translate('collections.userCollections.sectionTitleCreate')}</h3>
            <CreateCollection
              createCollectionWrapperClass='x-create-collection-wrapper'
              inputNamePlaceholder={translate('collections.userCollections.namePlaceholder')}
              inputDescriptionPlaceholder={translate('collections.userCollections.descriptionPlaceholder')}
              nameLabel={translate('collections.userCollections.nameLabel')}
              descriptionLabel={translate('collections.userCollections.descriptionLabel')}
              createCollectionClick={createCollectionClick}
              checkboxLabelText={translate('collections.userCollections.checkboxLabelText')}
              buttonTitleCreate={translate('collections.userCollections.buttonTitleCreate')}
              buttonTextCreate={translate('collections.userCollections.buttonTextCreate')}
              buttonTitleCancel={translate('common.forms.cancel')}
              buttonTextCancel={translate('common.forms.cancel')}
              designId={designId}
              userId={userId}
              clearSearch={clearSearch}
              hideCreateCollectionForm={hideCreateCollectionForm}
            />
          </section>
        }
        {!showCreateCollections &&
          <section className='user-collections-wrapper'>
            <h3 className='h2'>{translate('collections.userCollections.sectionTitleAdd')}</h3>
            <SearchCollections
              userCollectionsResults={userCollectionsResults}
              userIsSearching={userIsSearching}
              isSearching={isSearching}
              designId={designId}
              addOrRemoveDesign={addOrRemoveDesign}
              closeModal={closeModal}
              buttonTitleCreateNew={translate('collections.userCollections.buttonTitleCreateNew')}
              buttonTextCreateNew={translate('collections.userCollections.buttonTextCreateNew')}
              buttonDone={translate('collections.userCollections.buttonDone')}
              showCreateCollectionForm={showCreateCollectionForm}
            />
            {(recentUserCollectionsResults && !isSearching) &&
              <UserCollectionsList
                listTitle={translate('collections.userCollections.listTitleRecent')}
                userCollectionsResults={recentUserCollectionsResults}
                isAlphaOrder={false}
                designId={designId}
                addOrRemoveDesign={addOrRemoveDesign}
                isCollectionFetching={isCollectionFetching}
              />
            }
            {(designsInCollections && !isSearching) &&
              <UserCollectionsList
                listTitle={translate('collections.userCollections.listTitleAlreadyIn')}
                userCollectionsResults={designsInCollections}
                isAlphaOrder={false}
                designId={designId}
                addOrRemoveDesign={addOrRemoveDesign}
                isCollectionFetching={isCollectionFetching}
              />
            }
            {(userCollectionsResults && !isSearching) &&
              <UserCollectionsList
                listTitle={translate('collections.userCollections.listTitleAllOthers')}
                userCollectionsResults={filteredCollections()}
                isAlphaOrder={true}
                designId={designId}
                addOrRemoveDesign={addOrRemoveDesign}
                closeModal={closeModal}
                buttonTitleCreateNew={translate('collections.userCollections.buttonTitleCreateNew')}
                buttonTextCreateNew={translate('collections.userCollections.buttonTextCreateNew')}
                buttonDone={translate('collections.userCollections.buttonDone')}
                showCreateCollectionForm={showCreateCollectionForm}
                isCollectionFetching={isCollectionFetching}
              />
            }
          </section>
        }
      </article>
    </ErrorBoundary>
  );
};

export default AddToCollections;
