import React from 'react';
import PropTypes from 'prop-types';
import './_modal-button-group.scss';

 // TODO: EN-2368 Update Button Group to handle the case for buttons within collection modal
const ModalButtonGroup = ({buttonPrimaryText, buttonPrimaryTitle, buttonSecondaryText, buttonSecondaryTitle, primaryOnClick, secondaryOnClick}) => (
  <div className='b-modal-button-group'>
    <button
      type='button'
      className='btn blue-button big-button'
      aria-label={buttonPrimaryTitle}
      title={buttonPrimaryTitle}
      onClick={primaryOnClick}
    >
      {buttonPrimaryText}
    </button>
    <button
      type='button'
      className='btn grey-button big-button'
      aria-label={buttonSecondaryTitle}
      title={buttonSecondaryTitle}
      onClick={secondaryOnClick}
    >
      {buttonSecondaryText}
    </button>
  </div>
);

ModalButtonGroup.propTypes = {
  buttonPrimaryTitle: PropTypes.string,
  buttonPrimaryText: PropTypes.string,
  buttonSecondaryTitle: PropTypes.string,
  buttonSecondaryText: PropTypes.string,
  primaryOnClick: PropTypes.func,
  secondaryOnClick: PropTypes.func
};

export default ModalButtonGroup;
