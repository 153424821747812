// Matches "all non-whitespace characters (\S) standing right at the beginning of string (^) or after any whitespace
// character (\s)" Source: http://stackoverflow.com/questions/2332811/capitalize-words-in-string#comment24326531_7592235
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import {isNotUndefined} from './validation';
import {REGEX_SNAKE_CASE_TO_CAMEL_CASE} from '../constants/Validation';

const nonWhitespaceAdjacentToWhitespace = /(?:^|\s)\S/g;

// Corresponding to Ruby's 'titleize', i.e. 'some word' -> 'Some Word'
export function capitalize(string) {
  if (isNotUndefined(string)) {
    return string.replace(nonWhitespaceAdjacentToWhitespace, function(s) {
      return s.toUpperCase();
    });
  }
}
export function lowercaseFromCapitalized(string) {
  // this only puts the first letter to lowercase
  return string.replace(nonWhitespaceAdjacentToWhitespace, function(s) {
    return s.toLowerCase();
  });
}

// used to make json object out of string
export function removeQuotes(string) {
  if (typeof string === 'string' || string instanceof String) {
    string = string.replace(/^['"]+|\s+|\\|(;\s?})+|['"]$/g, '');
  }

  return string;
}

export const trimmedLowerString = (string, startLength, endLength) => (
  string.toLowerCase().substring(startLength, endLength)
);

export function snakeCaseFromCamelCase(string) {
  return string.replace(REGEX_SNAKE_CASE_TO_CAMEL_CASE, function(word) {
    return word[1].toUpperCase();
  });
}

// UpperCamelCase
export function pascalCase(string) {
  return upperFirst(camelCase(string));
}
