import get from 'lodash/get';
import {capitalize} from './string';
import {translate} from '../services';
import {isNotUndefined, isUndefined} from './validation';
import {EN} from '../constants/SupportedLanguages';
import {REGEX_AND_AMPERSANDS} from '../constants/Validation';
import {MAX_TITLE_LENGTH, MAX_DESCRIPTION_TITLE_LENGTH} from '../constants/Design';

export const createMetaTags = (tag) => {
  const capitalizedTag = capitalize(tag);

  return {
    capitalizedTag,
    documentTitle: translate('tags.head.title', {
      tag: capitalizedTag
    }),
    metaDescription: translate('tags.head.meta.description', {
      tag
    }),
    metaKeywords: translate('tags.head.meta.keywords', {
      tag
    }),
    ogTitle: translate('tags.head.meta.og.title', {
      tag
    }),
    ogDescription: translate('tags.head.meta.og.description', {
      tag
    })
  };
};

export const correctAmpersand = (queryTerm) => {
  if (isUndefined(queryTerm)) {
    return '';
  }

  return queryTerm.replace(REGEX_AND_AMPERSANDS,'&');
};

export const designDisplayTitle = (tags, designName, locale, titleLength = MAX_TITLE_LENGTH) => {
  const tagNames = tags && tags.map((tag) => (`${get(tag, 'name', tag)}`)); // use just tag if tag.name is undefined
  const tagNamesString = isNotUndefined(tagNames) ? tagNames.toString().replace(/,/g, ', ') : '';
  const truncatedDesignTitle = isNotUndefined(designName) ? shortenToWholeWords(designName, titleLength) : tagNamesString;

  return locale === EN ? truncatedDesignTitle : tagNamesString;
};

export const homeGoodDesignTitle = (designName, homeGoodType) => {
  const designTitlePostfixTranslationLength = translate('titles.fabricPage.title', {
    designTitle: ''
  }).length;
  // adding 1 to the length of the home good type name to account for space before the name
  const homeGoodTypeLength = translate(`homeGood.${homeGoodType}.name`).length + 1;
  const desigNameMaxLength = 60 - homeGoodTypeLength - designTitlePostfixTranslationLength;
  const truncatedDesignName = isNotUndefined(designName) ? shortenToWholeWords(designName, desigNameMaxLength) : '';

  return `${truncatedDesignName} ${translate(`homeGood.${homeGoodType}.name`)}`;
};

export const shortenToWholeWords = (str, maxLength = MAX_DESCRIPTION_TITLE_LENGTH, separator = ' ') =>
  ((str.length <= maxLength) ? str : str.substr(0, str.lastIndexOf(separator, maxLength)));

